import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Head from 'components/head';
import SectionMain from 'components/section-main';
import SectionMainHead from 'components/section-main-head';
import Title from 'components/title';
import CtaButton from 'components/ctabutton';
import List from 'components/list';
import { AsideWithGraph, AsideFull } from 'components/section-main/aside.css';
import COLORS from 'constants/colors';
import PatternTriangle4Inline from 'images/pattern-triangle4-inline.svg';
import PatternTriangle from 'images/pattern-triangle.svg';
import PatternCircual from 'images/pattern-circual.svg';
import PatternTripleTrianglePink from 'images/pattern-triple-triangle-pink.svg';
import PatternSquareBlu from 'images/pattern-square-blu.svg';
import { gridTemplateColumns } from 'constants/grid';

const Associazione = ({ data }) => {
  const PAGE = data.associazioneJson;
  const NONSOLO = data.associazioneJson.sections[0];
  const CALENDARIO = data.associazioneJson.sections[1];

  return (
    <>
      <Head
        pageTitle={PAGE.title}
        imageUrl={PAGE.socialImage.childImageSharp.fixed.src}
      />

      <SectionMainHead data={PAGE} />

      <SectionMain>
        <Title as="h2" color="green">
          {NONSOLO.title}
        </Title>

        <div
          className="offsetRight-tablet"
          dangerouslySetInnerHTML={{
            __html: NONSOLO.content.childMarkdownRemark.html,
          }}
        />

        <CtaButton
          linkTo="/regolamento"
          fontWeight="bold"
          style="primary-green"
        >
          {NONSOLO.cta2}
        </CtaButton>

        <CtaButton
          href="/statuto-oltredanza.pdf"
          asComponent="a"
          target="_blank"
          fontWeight="bold"
          style="rounded"
          icon="download"
        >
          {NONSOLO.cta1}
        </CtaButton>

        <AsideWithGraph theme={{ align: 'right' }}>
          <PatternCircual fill={COLORS.blue} />
          <Img fluid={NONSOLO.aside.image.childImageSharp.fluid} />
          <PatternTriangle4Inline fill={COLORS.white} />
          <PatternTriangle fill={COLORS.pink} />
        </AsideWithGraph>
      </SectionMain>

      <SectionMain
        bgColor="blue"
        bgPattern={true}
        theme={{ gridColumns: gridTemplateColumns.col57 }}
      >
        <PatternSquareBlu />
        <Title as="h2" color="pink" bordered={true} size="small">
          {CALENDARIO.title}
        </Title>

        <AsideFull>
          <List
            className="doubleColumn"
            list={CALENDARIO.list}
            textColor="white"
            colorUnderline="darkGreen"
          />
        </AsideFull>
        <PatternTripleTrianglePink />
      </SectionMain>
    </>
  );
};

Associazione.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Associazione;

export const query = graphql`
  query {
    associazioneJson {
      title
      socialImage {
        childImageSharp {
          fixed(width: 1025, height: 512, cropFocus: CENTER) {
            src
          }
        }
      }
      sections {
        title
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        cta1
        cta2
        aside {
          image {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        list {
          title
          subTitle
        }
      }
    }
  }
`;
